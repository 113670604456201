//全局检查器列表
let observerList = {};

//加载图片
function loadImg(elem, observer, dataA, imgs, loadExistImg) {
    let target = jm(elem);
    let original = target.attr(dataA);
    let imgSrc = '';
    if (window._aid != 9063789) {
        imgSrc = img2Webp(original);
    } else {
        imgSrc = original;
    }
    jm('<img />')
        .one('load', () => {
            if (target.is('img')) {
                target.attr('src', imgSrc);
            } else if (target.is('div') && target.find('image').length) {
                target.find('image').attr('xlink:href', imgSrc);
            } else {
                target.css('background-image', "url('" + imgSrc + "')");
            }
            target.addClass('fade-in-for-lazyload');

            var timer = setTimeout(function () {
                target.removeClass('fade-in-for-lazyload');
                clearTimeout(timer);
                timer = null;
            }, 600);
            //取消监听
            observer.unobserve(elem);

            //替换同类图片
            if (loadExistImg) {
                imgs.each(function (index, item) {
                    if (jm(item).attr(dataA) == original) {
                        replaceExistImg(item, observer, dataA);
                    }
                });
            }
        })
        .attr('src', imgSrc);
}

function replaceExistImg(elem, observer, dataA) {
    let target = jm(elem);
    let original = target.attr(dataA);
    let imgSrc = '';
    if (window._aid != 9063789) {
        imgSrc = img2Webp(original);
    }

    if (target.is('img')) {
        target.attr('src', imgSrc);
    } else if (target.is('div') && target.find('image').length) {
        target.find('image').attr('xlink:href', imgSrc);
    } else {
        target.css('background-image', "url('" + imgSrc + "')");
    }
    observer.unobserve(elem);
}

// eslint-disable-next-line no-unused-vars
export function isSupportWebp() {
    var isSupportWebpVar = false;

    try {
        isSupportWebpVar = document.createElement('canvas').toDataURL('image/webp').indexOf('data:image/webp') == 0;
    } catch (err) {
        console.log(err);
    }

    // eslint-disable-next-line no-func-assign
    isSupportWebp = function () {
        return isSupportWebpVar;
    };

    return isSupportWebp();
}

function img2Webp(path = '') {
    if (Fai.top._openWebp && isSupportWebp() && !Fai.top._webDebug) {
        if (/(.faiusr.com)/.test(path)) {
            path = path.replace(/(jpg|jpeg|png|gif)$|(jpg|jpeg|png|gif)\?v=/, function (match, key1, key2) {
                if (key1) {
                    // 匹配图片结尾，没有带版本号
                    return key1 + '.webp';
                } else if (key2) {
                    // 匹配图片结尾，有带版本号，如 png?v=2018
                    return match.replace(key2, key2 + '.webp');
                }
            });
        }
    }

    return path;
}

export function bindImgLazyLoad(id, options) {
    let settings = {
        root: null, //指定目标元素所在的容器节点（即根元素）。注意，容器元素必须是目标元素的祖先节点,没有即默认窗口可视区域范围
        threshold: 0.01, //属性决定了什么时候触发回调函数。它是一个数组，每个成员都是一个门槛值，默认为[0] 即交叉比例（intersectionRatio）达到0时触发回调函数
        rootMargin: '0px', //定义根元素的margin,用来扩展可视区的范围比如10px 20px 30px 40px，表示 top、right、bottom 和 left 四个方向的值。
        imgClass: 'J_img_lazyload', //绑定懒加载开始至图片load完期间需要的class
        data_attribute: 'src-original', //src-original 用于替换 src 的属性名
        loadExistImg: false,
    };

    if (options) {
        jm.extend(settings, options);
    }

    let loadExistImg = settings.loadExistImg;
    let imgClass = settings.imgClass,
        dataA = settings.data_attribute,
        bindingElem = jm('#' + id),
        imgs = bindingElem.find('.' + imgClass);

    // 删除旧的检查器
    if (observerList[id]) {
        observerList[id].disconnect();
        observerList[id] = null;
    }

    // 注册检查器
    let observer = new IntersectionObserver(
        (entries) => {
            entries.forEach((val) => {
                if (val && val.isIntersecting && val.target) {
                    let target = jm(val.target);
                    let picPath = target.attr(dataA);
                    if (picPath) {
                        // 加载图片
                        loadImg(val.target, observer, dataA, imgs, loadExistImg);
                    }
                }
            });
        },
        {
            root: settings.root,
            threshold: settings.threshold,
            rootMargin: settings.rootMargin,
        }
    );

    observerList[id] = observer;

    imgs.each((index, val) => {
        observer.observe(val);
    });
}
